import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  mdiChevronDoubleUp,
  mdiLinkedin,
  mdiFacebook,
  mdiInstagram,
  mdiCodepen,
  mdiRocketLaunch,
  mdiRocket,
  mdiRocketLaunchOutline,
  mdiRocketOutline,
  mdiFitToPage,
  mdiSetTopBox,
  mdiGithub,
} from "@mdi/js";
import Icon from "@mdi/react";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "#1b242f",
    padding: "50px 0",
    position: "relative",
  },
  topBtn: {
    backgroundColor: "#7B7878",
    padding: "5px",
    cursor: "pointer",
    position: "absolute",
    top: "-30px",
    transform: "translateX(-50%)",
    color: "white",
    "&:hover": {
      backgroundColor: "#666C60",
    },
  },
  socialIcons: {
    margin: "15px",
    padding: "10px",
    backgroundColor: "#262F39",
    color: "white",
    display: "inline-flex",
    alignItems: "center",
    "&:hover, &:focus, &:active": {
      backgroundColor: "#666C60",
    },
  },
  copyright: {
    color: "#8f9AA7",
    textTransform: "uppercase",
    fontSize: "15px",
    "& span": {
      color: "#689C05",
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
});

export default function Footer() {
  const classes = useStyles();

  const social = [
    ["http://linkedin.com", "Linkedin profile", mdiLinkedin],
    ["http://facebook.com", "Facebook profile", mdiFacebook],
    ["http://instagram.com", "Instagram profile", mdiInstagram],
    ["http://github.com", "Github profile", mdiGithub],
  ];
  return (
    <footer className={classes.footer}>
      <a href="#home">
        <Icon
          path={mdiRocketOutline}
          title="Back to top"
          size={1.5}
          className={classes.topBtn}
        />
      </a>
      <div>
        {social.map((social, i) => (
          <a
            href={social[0]}
            title={social[1]}
            key={i}
            className={classes.socialIcons}
          >
            <Icon path={social[2]} size={1} />
          </a>
        ))}
      </div>
      <p className={classes.copyright}>
        Roshan Shah <span>&copy; {new Date().getFullYear()}</span>
      </p>
    </footer>
  );
}
