import React from "react";
import Main from "./Main";
import "./fluid";
import { makeStyles } from "@material-ui/core/styles";
import Nav from "./Nav";
import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Footer from "./Footer";

const useStyles = makeStyles({
  "@global": {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      fontFamily: "Raleway, sans serif",
      color: "white",
      textAlign: "center",
      boxSizing: "border-box",
      overflowX: "hidden",
    },
    ".flex": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    ".flex-row": { flexDirection: "row" },
    ".container": {
      maxWidth: "1200px",
      width: "100%",
      margin: "0 auto",
      boxSizing: "border-box",
    },
    ".section-headline": {
      color: "#444649",
      fontSize: "35px",
      position: "relative",
      display: "inline-block",
      margin: 0,
      textTransform: "uppercase",
      "&::after": {
        content: "''",
        bottom: "-20px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "50%",
        height: "4px",
        position: "absolute",
        backgroundColor: "#444649",
      },
    },
    ".section-padding": {
      padding: "100px 15px 130px !important",
    },

    ".swiper-container": {
      height: "320px",
    },
    ".swiper-wrapper": {
      height: "320px",
    },
  },
});

function App() {
  useStyles();
  return (
    <div>
      <Main />
      <Nav />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
