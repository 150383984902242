import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  contact: {
    backgroundColor: "#252934",
    paddingBottom: "130px",
  },
  headline: {
    margin: "100px auto 40px !important",
    color: "white !important",
    "&::after": {
      backgroundColor: "white !important",
    },
  },
  contactForm: {
    maxWidth: "500px",
    padding: "0 15px",
    margin: "40px auto 0",
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  input: {
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid #949494",
    boxSizing: "border-box",
    color: "#949494",
    fontSize: "18px",
    marginBottom: "3px",
    outline: "none",
    padding: "10px 15px",
    width: "45%",
    fontFamily: "inherit",
    resize: "none",
    "&::placeholder": {
      fontSize: "20px",
      color: "#949494",
    },
    "&:focus": {
      color: "#c3c3c3",
    },
  },
  textArea: {
    width: "100%",
    marginTop: "15px",
    transition: "height 0.3s",
    "&:focus": {
      height: "200px",
    },
  },
  submitBtn: {
    backgroundColor: "transparent",
    border: "1px solid #949494",
    borderRadius: "3px",
    fontSize: "20px",
    color: "#949494",
    outline: "none",
    padding: "10px 30px",
    marginTop: "35px",
    fontFamily: "inherit",
    alignSelf: "center",
    "&:hover,&:focus,&:active": {
      borderColor: "#c3c3c3",
      outline: "none",
      color: "#c3c3c3",
      cursor: "pointer",
    },
  },
});

export default function Contact() {
  const classes = useStyles();
  return (
    <section className={classes.contact} id="contact">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 100 102"
        height="75"
        width="100%"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="svgcolor-light"
        style={{ marginTop: "-1px" }}
      >
        <path d="M0 0 L50 100 L100 0 Z" fill="#f5f5f5" stroke="#f5f5f5"></path>
      </svg>

      <h3 className={`${classes.headline} section-headline`}>Contact</h3>
      <p style={{ color: "#689C05" }}>
         Please leave your message or send me an email to <span style={{ color: "white" }}>Roshan@blockofcodes.com</span> 
      </p>
      <form
        action="https://send.pageclip.co/W0CWUwI2L2iOmozBoNzEmxwLAaxAboBm"
        className={classes.contactForm}
        method="post"
      >
        <div className={classes.inputContainer}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            className={classes.input}
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            className={classes.input}
          />
        </div>
        <textarea
          name="message"
          placeholder="Your Message"
          className={`${classes.input} ${classes.textArea}`}
        />
        <input
          type="submit"
          value="Submit"
          id="submit"
          className={classes.submitBtn}
        />
      </form>
    </section>
  );
}
