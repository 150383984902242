import React from "react";
import { Dialog, Slide, IconButton, makeStyles } from "@material-ui/core";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    "& .MuiPaper-root": {
      width: "600px",
      maxWidth: "100%",
      margin: "10px",
    },
  },
  dialogContainer: {
    width: "600px",
    maxWidth: "100%",
    textAlign: "left",
    overFlow: "none",
  },
  closeButton: {
    color: "grey",
    marginLeft: "auto",
    padding: 0,
    display: "block",
    textAlign: "right",
  },
  boldTitle: {
    fontWeight: "700",
    color: "#444",
    margin: 0,
  },
  slogan: {
    marginTop: "5px",
    color: "#c0c0c0",
    textTransform: "uppercase",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      bottom: "-15px",
      left: 0,
      width: "100%",
      backgroundColor: "#efebeb",
      height: "1px",
    },
  },
});

export default function ProjectDialog(props) {
  const { open, setOpen, dialogContent } = props;
  const { images, title, slogan, description } = dialogContent;
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Project Dialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      className={classes.dialog}
    >
      <div className={classes.dialogContainer}>
        <Swiper {...params} className={classes.slider}>
          {images.map((image, i) => (
            <img
              src={image}
              key={i}
              alt="project site"
              style={{ height: "100%" }}
            />
          ))}
        </Swiper>
        <div style={{ padding: "30px" }}>
          <h3 className={classes.boldTitle}>{title}</h3>
          <h5 className={classes.slogan}>{slogan}</h5>
          <p style={{ color: "#444" }}>{description}</p>
          <div className="flex flex-row"></div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Icon
              path={mdiClose}
              title={`${open ? "Close" : "Open"} Dialog`}
              size={1}
              color="grey"
            />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
}
