import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { NavActiveContext } from "./NavActiveContext";
// import { InView } from "react-intersection-observer";
import { mdiBookEducation, mdiBookEducationOutline, mdiCodeJson, mdiFileCodeOutline, mdiSpeedometer } from "@mdi/js";
import { mdiCellphoneLink } from "@mdi/js";
import { mdiLightbulb } from "@mdi/js";
import { mdiRocketLaunch } from "@mdi/js";
import Icon from "@mdi/react";
import avatar from "./images/avatar.jpg";

const useStyles = makeStyles({
  benefitsContainer: {
    margin: "60px auto 50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  benefitWrapper: {
    maxWidth: "300px",
    marginBottom: "40px",
  },
  benefitIcon: {
    backgroundColor: "grey",
    height: "80px",
    width: "160px",
    borderRadius: "100px",
  },
  avatar: {
    width: "200px",
    maxWidth: "80%",
    height: "180px",
    borderRadius: "50%",
  },
  infoContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  infoParts: {
    width: "47.5%",
  },
  skillWrapper: {
    fontSize: "15px",
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-between",
    height: "22px",
    color: "#666",
    paddingRight: "10px",
    marginBottom: "20px",
  },
  skillDisplay: {
    fontWeight: 600,
    display: "flex",
    backgroundColor: "#696969",
    color: "white",
    height: "25px",
    "& span": { lineHeight: "22px" },
  },
  "@media (max-width: 959px)": {
    infoParts: { width: "90%", margin: "20px auto" },
  },
});

export default function About() {
  // const [, setNavActive] = useContext(NavActiveContext);
  const classes = useStyles();

  const benefits = [
    [
      "Programming Language",
      "Java, JavaScript, PHP",
      mdiCodeJson,
    ],
    [
      "Skills",
      "ReactJs, HTML, CSS, Git & Github, Postman,SQL databases",
      mdiFileCodeOutline,
    ],
    [
      "Education",
      "Bachelor in Computer Information System (Monroe College - 3.90 GPA)",
      mdiBookEducationOutline,
    ],
  ];

  const skills = [
    ["HTML", "90%"],
    ["CSS", "90%"],
    ["React", "70%"],
    ["JavaScript", "70%"],
    ["PHP", "70%"],
    ["Java", "60%"],
  ];
  return (
    <section
      className="section-padding container"
      style={{ color: "#616161" }}
      id="about"
    >
      <h1 className="section-headline">About</h1>
      <div className={classes.benefitsContainer}>
        {benefits.map((benefit) => (
          <div className={`flex ${classes.benefitWrapper}`}>
            <div className={`flex ${classes.benefitIcon}`}>
              <Icon path={benefit[2]} size={2} color="white" />
            </div>
            <h4 style={{ margin: "15px 0 10px" }}>{benefit[0]}</h4>
            <p style={{ fontSize: "16px", margin: 0 }}>{benefit[1]}</p>
          </div>
        ))}
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.infoParts}>
          <img src={avatar} alt="Roshan Shah" className={classes.avatar} />
          <h3>Who am I?</h3>
          <p>
            I am Roshan Shah, Graduated from Monroe College, with Bachelor
            in Computer Information System (3.90 GPA).I have a good Knowledge of Application & Software development possessing problem-solving and logical thinking.
            <br />
          </p>
        </div>
        <div className={classes.infoParts}>
          {skills.map((skill) => (
            <div className={classes.skillWrapper}>
              <div style={{ width: skill[1] }} className={classes.skillDisplay}>
                <span
                  style={{
                    width: "121px",
                    backgroundColor: "grey",
                  }}
                >
                  {skill[0]}
                </span>
              </div>
              <span>{skill[1]}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
