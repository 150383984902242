import React from "react";
import { makeStyles, Tabs, Tab } from "@material-ui/core";
import reactProject from "./images/react.png";
import eventProject from "./images/event.png";
import bookingProject from "./images/booking.jpg";
import Project from "./Project";

const useStyles = makeStyles({
  portfolio: {
    backgroundColor: "#f5f5f5",
  },
  tabs: {
    color: "#252934",
    "& .MuiTabs-indicator": {
      backgroundColor: "#e31b6d",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function Portfolio() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const projects = [
    {
      img: reactProject,
      title: "Benchmark Tech Admin",
      language: "React",
      category: "React",
      dialog: {
        images: [reactProject, reactProject],
        title: "Benchmark Tech Admin with Database connection",
        slogan: "Slogan",
        description: "Description",
      },
    },
    {
      img: eventProject,
      title: "Event Project",
      language: "HTML/CSS",
      category: "Java",
      dialog: {
        images: [eventProject, eventProject],
        title: "Event Booking App",
        slogan: "Slogan",
        description: "Description",
      },
    },
    {
      img: bookingProject,
      title: "Booking Project",
      language: "Java",
      category: "Java",
      dialog: {
        images: [bookingProject, bookingProject],
        title: "Title",
        slogan: "Slogan",
        description: "description",
      },
    },
  ];
  return (
    <section className={`section-padding ${classes.portfolio}`} id="portfolio">
      <div className="container">
        <h1 className="section-headline">Projects & Certifications</h1>
        <div style={{ marginTop: "115px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.tabs}
            centered
          >
            <Tab label="All" />
            <Tab label="JavaScript" />
            <Tab label="ReactJs" />
            <Tab label="Certifications" />
          </Tabs>
          <TabPanel value={value} index={0}>
            {projects.map((project, i) => (
              <Project {...project} />
            ))}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {projects
              .filter((project) => project.category === "React")
              .map((project, i) => (
                <Project {...project} />
              ))}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {projects
              .filter((project) => project.category === "Java")
              .map((project, i) => (
                <Project {...project} />
              ))}
          </TabPanel>
          
        </div>
      </div>
    </section>
  );
}
