import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { mdiMenu, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import Scrollspy from "react-scrollspy";

const useStyles = makeStyles({
  nav: {
    backgroundColor: "#1b242f",
    position: "sticky",
    top: 0,
    left: 0,
    height: "50px",
    marginTop: "-1px",
    display: "flex",
    alignItems: "center",
    zIndex: 3,
  },
  navLinksContainer: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    textAlign: "left",
  },
  link: {
    color: "white",
    textTransform: "uppercase",
    textDecoration: "none",
    fontSize: "16px",
    marginLeft: "40px",
    "&:first-child": {
      marginLeft: "20px",
    },
    "&:hover": { cursor: "pointer", color: "#e31b6d" },
    "&:focus": { outline: "none", color: "#e31b6d" },
  },
  linkActive: { color: "#e31b6d" },
  navBtn: {
    margin: "0 20px 0 auto",
    outline: "none",
    background: "transparent",
    border: "none",
    color: "white",
    display: "none",
    "&:hover": { cursor: "pointer" },
  },
  "@media (max-width: 440px)": {
    navLinksContainer: {
      display: ({ open }) => (open ? "block" : "none"),
    },
    navWrap: {
      position: "absolute",
      top: "50px",
      left: 0,
      right: 0,
      padding: "20px",
      backgroundColor: "#333",
      flexDirection: "column !important",
    },
    navBtn: {
      display: "block",
    },
    link: {
      marginLeft: "0 !important",
      marginBottom: "15px",
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
});
export default function Nav() {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  const navLinks = [
    ["Home", "#home"],
    ["About", "#about"],
    ["Portfolio", "#portfolio"],
    ["Contact", "#contact"],
    ["Resume", "resume.php"],
  ];

  const handleNavMenuClick = () => setOpen(!open);
  return (
    <nav className={classes.nav}>
      <div className={classes.navLinksContainer}>
        <Scrollspy
          componentTag="div"
          className={`${classes.navWrap} flex flex-row`}
          currentClassName={classes.linkActive}
          items={["home", "about", "portfolio", "contact","resume"]}
          offset={-100}
        >
          {navLinks.map((link, i) => (
            <a key={i} className={classes.link} href={link[1]}>
              {link[0]}
            </a>
          ))}
        </Scrollspy>
      </div>
      <button className={classes.navBtn}>
        <Icon
          path={open ? mdiClose : mdiMenu}
          title={open ? "Close Menu" : "Open Menu"}
          size={1}
          className={classes.navIcon}
          onClick={handleNavMenuClick}
        />
      </button>
    </nav>
  );
}
