import React from "react";
import { makeStyles } from "@material-ui/core";
import ProjectDialog from "./ProjectDialog";

const useStyles = makeStyles({
  card: {
    justifySelf: "center",
    position: "relative",
    width: "390px",
    maxWidth: "100%",
    height: "300px",
    "&:hover": {
      "& > $bg": { opacity: 0 },
      "& > $text, & > $learnBtn": {
        opacity: 1,
        transform: "translateY(0)",
        transition: "all 0.5s",
      },
    },
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "390px",
    maxWidth: "100%",
    height: "300px",
    background: ({ img }) => `url(${img}) center center/contain no-repeat`,
    transition: "opacity 0.5s",
  },
  text: {
    opacity: 0,
    transform: "translateY(-50px)",
    marginBottom: 0,
    color: "#1b242f",
    zIndex: -21123,
    "& h4": {
      textTransform: "uppercase",
      margin: "70px auto 0",
    },
    "& p": {
      color: "#e31b6d",
      fontSize: "16px",
      marginTop: 0,
    },
  },
  learnBtn: {
    opacity: 0,
    transform: "translateY(50px)",
    border: "2px solid #e31b6d",
    outline: "none",
    background: "none",
    fontWeight: 300,
    fontSize: "18px",
    width: "170px",
    padding: "7px 0",
    textAlign: "center",
    color: "#1b242f",
    zIndex: 3,
    marginTop: "50px",
    "&:hover,&:active,&:focus": {
      cursor: "pointer",
      backgroundColor: "#e31b6d",
      color: "white",
    },
  },
});
export default function FirstProject({ img, title, language, dialog }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const classes = useStyles({ img });
  return (
    <div className={classes.card}>
      <div className={classes.bg}></div>
      <div className={classes.text}>
        <h4>{title}</h4>
        <p>{language}</p>
      </div>
      <button className={classes.learnBtn} onClick={handleClickOpen}>
        Learn More
      </button>

      <ProjectDialog open={open} setOpen={setOpen} dialogContent={dialog} />
    </div>
  );
}
