import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiHandRight } from "@mdi/js";
import Typical from 'react-typical';

const useStyles = makeStyles({
  main: {
    height: "100vh",
  },
  
  canvasContainer: {
    backgroundColor: "rgb(37, 41, 52)",
    background:
      "radial-gradient(ellipse at center, rgba(37, 41, 52, 1) 0%,rgba(37, 41, 52, 1) 100%)",
    margin: 0,
    padding: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  canvas: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100vh",
    zIndex: "1",
  },
  name: {
    color: "white",
    fontWeight: 400,
  },
  seeWorkBtn: {
    display: "flex",
    alignItems: "center",
    color: "white",
    fontFamily: "inherit",
    background: "none",
    borderRadius: "100px ",
    border: "2px solid white",
    padding: "12px 15px",
    fontSize: "16px",
    outline: "none",
    marginBottom: "20px",
    textDecoration: "none",
    
    zIndex: 20,
    "&:hover, &:focus": {
      cursor: "pointer",
      backgroundColor: "#7C7474",
      borderColor: "#7C7474",
      borderRadius: "100px",
      transition: "all 0.5s",
      
      "& svg": {
        transform: "rotate(90deg)",
      },
    },
  },
  workArrow: {
    marginLeft: "5px",
    transition: "all 1.1s",
  },
});

export default function Main() {
  const classes = useStyles();
  return (
    <main className="flex" style={{ height: "100vh" }} id="home">
      <div className={classes.canvasContainer}>
        <canvas
          id="stars"
          width="300"
          height="300"
          className={classes.canvas}
        ></canvas>
      </div>

      <div className="flex" style={{ position: "relative" }}>
        <h1 className={classes.name}>
          Hello, I'm <strong><span style={{ color: "#689C05" }}>Roshan Shah</span>.{" "}</strong>
          <br />
          I'm a 
        <Typical
        loop={Infinity}
        wrapper="b"
        steps={[
          ' Developer',
          1000,
          ' Freelancer',
          1000,
          ' Designer',
          1000
        ]}
        />
        </h1>

        <a className={classes.seeWorkBtn} href="#contact">
          Say Hello{" "}
          <Icon
            path={mdiHandRight}
            size={0.9}
            color="#689C05"
            className={classes.workArrow}
            
          />
        </a>
      </div>
    </main>
  );
}
